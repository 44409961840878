<template>
    <v-dialog
        :width="500"
        persistent
        v-model="editDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
    >
        <v-card>
            <v-card-title class="success white--text">
                <span>{{ $t('message.linkStockOrder') + ": " + stockOrder }}</span>
            </v-card-title>
            <v-card-text>
                <v-overlay
                    :value="loading.get"
                    absolute
                    opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                color="primary"
                                indeterminate
                                size="40"
                                width="6"
                            ></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-form lazy-validation ref="linkPurchaseOrderForm" v-model="validForm">
                    <v-layout row pt-3>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.purchaseOrder') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-autocomplete
                                :items="purchaseOrders"
                                :rules="[rules.purchaseOrder]"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                item-text="Purchaseorder.title"
                                item-value="Purchaseorder.id"
                                solo
                                v-model="purchaseorder_id"
                            >
                                <template v-slot:item="{item}">
                                    <div class="d-flex flex-column py-1">
                                        <span class="font-weight-bold">{{ item.Purchaseorder.title}}</span>
                                        <span class="font-sm" v-if="item.Supplier.title != null"> {{ $t('message.supplier') + ': ' + item.Supplier.title }} </span>
                                    </div>
                                </template>
                            </v-autocomplete>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pl-7">
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                    :loading="loading.save"
                    color="info"
                    small
                    @click="saveLink()"
                >{{ $t('message.save') }}</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {api} from "Api";

export default {
    name: "LinkPurchaseOrder",
    props: ['stockOrder','stockOrderId','purchaseOrderId','warehouseId','dialog','updateMode'],
    data(){
        return {
            dialogs: {
                error: false,
                error_message: ""
            },
            loading: {
                add: false,
                get: false,
                save: false
            },
            edit_dialog: false,
            payload: {
                tableOptions: null
            },
            purchaseorder_id: [],
            purchaseOrders: [],
            rules: {
                purchaseOrder: v => !!v || this.$t('message.required')
            },
            validForm: true
        }
    },
    computed: {
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        }
    },
    methods: {
        getPurchaseOrders() {
            return new Promise((resolve, reject) => {
                api
                    .get("/purchaseorders/warehouse", {
                        params: {
                            conditions: [
                                {
                                    field: 'Contract.office_id',
                                    value: this.warehouseId
                                }
                            ]
                        }
                    })
                    .then(response => {
                        this.purchaseOrders = response.data.data
                        resolve('done')
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        saveLink() {
            if(this.$refs.linkPurchaseOrderForm.validate()) {
                this.loading.save = true
                api
                    .put('/stockorders/' + this.stockOrderId + '/link', {
                        purchaseorder_id: this.purchaseorder_id
                    })
                    .then(response => {
                        if(response.data.status == 'success') {
                            const selectedPurchaseOrder = this.purchaseOrders.find(po => po.Purchaseorder.id == this.purchaseorder_id)
                            this.$toast.success(this.$t('message.successes.purchaseOrderLinked'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.$emit('linking-done', {
                                stockOrderId: this.stockOrderId,
                                linkedPurchaseOrder: selectedPurchaseOrder.Purchaseorder.title,
                                linkedPurchaseOrderId: selectedPurchaseOrder.Purchaseorder.id
                            })
                        } else {
                            this.$toast.error(this.$t('message.errors.purchaseOrderNotLinked'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                        this.loading.save = false
                    })
                    .catch((e) => {
                        this.$toast.error(e,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            }
        }
    },
    watch: {
        dialog(value) {
            if(value == true){
                // if(this.purchaseOrders.length == 0) {
                    this.getPurchaseOrders()
                        .then(() => {
                            if (this.updateMode == true) {
                                this.purchaseorder_id = this.purchaseOrderId
                            } else {
                                this.purchaseorder_id = null
                            }
                            this.edit_dialog = value
                        })
                        .catch(() => {
                            this.edit_dialog = value
                        })
                // } else {
                //     if (this.updateMode == true) {
                //         this.purchaseorder_id = this.purchaseOrderId
                //     } else {
                //         this.purchaseorder_id = null
                //     }
                //     this.edit_dialog = value
                // }
            } else {
                this.purchaseorder_id = null
                this.edit_dialog = value
            }
        }
    },
    mounted() {
        if(this.purchaseOrders.length == 0) this.getPurchaseOrders()
    }
}
</script>

<style scoped>

</style>